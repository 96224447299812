import React from 'react';

const SampleHelp = () => {
    return (
        <div>
            <h1>Sample Help</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquid aut delectus dolore dolorum excepturi hic nam omnis optio provident quia reiciendis repudiandae sit, tenetur!</p>
            <ul>
                <li>List item</li>
                <li>List item</li>
                <li>List item</li>
            </ul>
            <h2>Second title</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquid aut delectus dolore dolorum excepturi hic nam omnis optio provident quia reiciendis repudiandae sit, tenetur!</p>
            <ol>
                <li>List item</li>
                <li>List item</li>
                <li>List item</li>
            </ol>
        </div>
    )
}

export default SampleHelp;