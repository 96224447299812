import React from "react";
import NumericCohortChart from "./NumericCohortChart";
import DiscreteCohortChart from "./DiscreteCohortChart";
//import { Typography } from "@material-ui/core";

/*
    value is an object with the following field examples
    value = {
        id: 1 //unique number of this cohort variable
        type: 'discrete' //type of data 'discrete', 'continuous', 'integer'
        name: 'Age' //title for the plot
        data: [{x: 1, y:1}, {x: 2, y: 2}] //array of objects with x and y pairs
        domain: ['a', 'b', 'c'] //array of the limits of the y data only for 'discrete'
    }
    color = color to draw chart
    filter =[0, 10] or ['a', 'b', 'c'] // this is the current active filter setting on data
    onChange  //this is callback with is called with filter as parameter on filter change. 
    onRemove  //this is called when this chart is removed
    
*/

function CohortChart({ value, color, filter, onChange, onRemove }) {
  if (!value) return null;

  return (
    <div
      key={value.id}
      style={{
        borderBottom: "1px solid #ccc",
        paddingBottom: "1rem",
        marginBottom: "1rem",
        width: "100%",
      }}
    >
      {value.type === "discrete" ? (
        <DiscreteCohortChart
          value={value}
          color={color}
          filter={filter}
          onChange={onChange}
          onRemove={onRemove}
        />
      ) : (
        <NumericCohortChart
          value={value}
          color={color}
          filter={filter}
          onChange={onChange}
          onRemove={onRemove}
        />
      )}
    </div>
  );
}

export default CohortChart;
