import {
    Errors,
    Credentials,
    SignInAction,
    Account,
    SignUpAction,
    SignUpVerifyAction,
    SignInForgotAction,
    SignInChangePasswordAction
} from "./models";

export const types = {
    SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAIL: 'SIGN_IN_FAIL',
    SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAIL: 'SIGN_UP_FAIL',
    SIGN_UP_VERIFY_REQUEST: 'SIGN_UP_VERIFY_REQUEST',
    SIGN_UP_VERIFY_SUCCESS: 'SIGN_UP_VERIFY_SUCCESS',
    SIGN_UP_VERIFY_FAIL: 'SIGN_UP_VERIFY_FAIL',
    SIGN_UP_RESEND_REQUEST: 'SIGN_UP_RESEND_REQUEST',
    SIGN_UP_RESEND_SUCCESS: 'SIGN_UP_RESEND_SUCCESS',
    SIGN_UP_RESEND_FAIL: 'SIGN_UP_RESEND_FAIL',
    SIGN_IN_FORGOT: 'SIGN_IN_FORGOT',
    SIGN_IN_FORGOT_FAIL: 'SIGN_IN_FORGOT_FAIL',
    SIGN_IN_CHANGE_PASSWORD: 'SIGN_IN_CHANGE_PASSWORD',
    SIGN_IN_CHANGE_PASSWORD_FAIL: 'SIGN_IN_CHANGE_PASSWORD_FAIL'
}

export const signInRequest = (credentials: Credentials): SignInAction => ({
    type: types.SIGN_IN_REQUEST,
    credentials
})

export const signInSuccess = (): SignInAction => ({
    type: types.SIGN_IN_SUCCESS
})

export const signInFail = (errors: Errors[]): SignInAction => ({
    type: types.SIGN_IN_FAIL,
    errors
})

export const signUpRequest = (account: Account): SignUpAction => ({
    type: types.SIGN_UP_REQUEST,
    account
})

export const signUpSuccess = (): SignUpAction => ({
    type: types.SIGN_UP_SUCCESS
})

export const signUpFail = (errors: Errors[]): SignUpAction => ({
    type: types.SIGN_UP_FAIL,
    errors
})

export const signUpVerifyRequest = (username: string, code: number): SignUpVerifyAction => ({
    type: types.SIGN_UP_VERIFY_REQUEST,
    username,
    code
})

export const signUpVerifySuccess = (): SignUpVerifyAction => ({
    type: types.SIGN_UP_VERIFY_SUCCESS
})

export const signUpVerifyFail = (errors: Errors[]): SignUpVerifyAction => ({
    type: types.SIGN_UP_VERIFY_FAIL,
    errors
})

export const signUpResendRequest = (username: string): SignUpVerifyAction => ({
    type: types.SIGN_UP_RESEND_REQUEST,
    username
})

export const signUpResendSuccess = (): SignUpVerifyAction => ({
    type: types.SIGN_UP_RESEND_SUCCESS
})

export const signUpResendFail = (errors: Errors[]): SignUpVerifyAction => ({
    type: types.SIGN_UP_RESEND_FAIL,
    errors
})

export const signInForgot = (username: string): SignInForgotAction => ({
    type: types.SIGN_IN_FORGOT,
    username
})

export const signInForgotFail = (errors: Errors[]): SignInForgotAction => ({
    type: types.SIGN_IN_FORGOT_FAIL,
    errors
})

export const signInChangePassword = (username: string): SignInChangePasswordAction => ({
    type: types.SIGN_IN_CHANGE_PASSWORD,
    username
})

export const signInChangePasswordFail = (errors: Errors[]): SignInChangePasswordAction => ({
    type: types.SIGN_IN_CHANGE_PASSWORD_FAIL,
    errors
})