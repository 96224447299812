import {
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoUser
} from 'amazon-cognito-identity-js'

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_POOL_ID,
  ClientId: process.env.REACT_APP_POOL_CLIENT
})

const register = async (firstName, lastName, username, password, phone) => {
  try {
    let attributeList = [
      new CognitoUserAttribute({ Name: 'preferred_username', Value: username }),
      new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
      new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
      new CognitoUserAttribute({ Name: 'custom:database', Value: 'PSYNTHDB' }),
    ]

    if(phone) attributeList.push(new CognitoUserAttribute({ Name: 'phone_number', Value: phone }))
    return await new Promise((resolve, reject) =>
      userPool.signUp(username, password, attributeList, null, (err, result) => {
        if(err) reject(err);
        resolve(result);
      }));
  } catch (e) {
    return e
  }
}

const login = async (username, password) => {
  try {
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    });
    const userData = {
      Username: username,
      Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)
    return await new Promise((resolve, reject) => cognitoUser.authenticateUser(authDetails, {
      onSuccess: resolve,
      onFailure: reject,
      mfaSetup: resolve,
      mfaRequired: resolve
    }));
  } catch (e) {
    return e
  }
}

const verify = async (username, code) => {
  try {
    const userData = {
      Username: username,
      Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)
    if(!code) return { message: 'Verification code is required' }
    return await new Promise((resolve, reject) => cognitoUser.confirmRegistration(code.toString(), true, (err, result) => {
      if(err) reject(err)
      resolve(result)
    }));
  } catch (e) {
    return e
  }
}

const resend = async (username, code) => {
  try {
    const userData = {
      Username: username,
      Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)
    return await new Promise((resolve, reject) => cognitoUser.resendConfirmationCode((err, result) => {
      if(err) reject(err)
      resolve(result)
    }));
  } catch (e) {
    return e
  }
}

const forgot = async (username) => {
  try {
    const userData = {
      Username: username,
      Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)
    return await new Promise((resolve, reject) => cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject
    }))
  } catch (e) {
    return e
  }
}

const changePass = async (username, code, password) => {
  try {
    const userData = {
      Username: username,
      Pool: userPool
    }
    const cognitoUser = new CognitoUser(userData)
    return await new Promise((resolve, reject) => cognitoUser.confirmPassword(code, password, {
      onSuccess: () => resolve(true),
      onFailure: reject
    }))
  } catch (e) {
    return e
  }
}

export {
  register,
  login,
  verify,
  resend,
  forgot,
  changePass
}