import React, { useRef, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Popper,
  Backdrop,
  useTheme,
  Typography,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import SankeyChart from "../SankeyChart";
import { connect } from "react-redux";
import { loadPopupChartData } from "../../redux/Charts/thunks";
import { cohortAdd } from "../../redux/Cohorts/actions";
import ChangeName from "../dialogs/ChangeName";
const useStyles = makeStyles((theme) => ({
  container: {
    height: "85vh",
  },
  item: {
    padding: theme.spacing(1),
    display: "flexbox",
    //width: "50%",
    //height: "75%",
    //flexShrink: 1,

    // alignItems: "center",
  },
  close: {
    paddingRight: theme.spacing(1),
  },
  loadingBox: {
    minWidth: 200,
    minHeight: 150,
  },
  popper: {
    zIndex: 9999,
    width: "calc(100vw - 90px)",
    height: "calc(100vh - 90px)",
  },
  nameChange: {
    zIndex: 10000,
  },
  backdrop: {
    zIndex: 9998,
  },
  sankey: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: "flexbox",
    // width: "50%",
    height: "100%",

    alignItems: "center",
  },
  paper: {
    maxHeight: "90vh",
    overflow: "hidden",
  },
  tooltip: {
    backgroundColor: "white",
    padding: theme.spacing(1),
    borderRadius: "8px",
    boxShadow: "0px 1px 8px 0px rgba(0,0,0,0.12)",
    "& > p": {
      margin: 0,
      textAlign: "center",
    },
  },
  barArea: {
    margin: "0 auto",
  },
}));

const PopupChart = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const popoverAnchor = useRef(null);
  const barNode = useRef(null);
  const {
    open,
    node,
    toggle,
    loading,
    popupData,
    cohortAdd,
    loadPopupChartData,
  } = props;

  const [changeName, setChangeName] = React.useState(false);
  const [active, setActive] = React.useState(true);

  const handleCreateCohort = () => {
    setChangeName((s) => !s);
    setActive(false);
  };

  function createCohort(name) {
    setChangeName((s) => !s);
    const ids = node.data.map((d) => d.ids);
    const newCohort = {
      name: name,
      ids: ids,
      variables: [],
    };
    cohortAdd(newCohort);
    toggle({ open: false, name: null });
  }

  useEffect(() => {
    setActive(open);
  }, [open]);

  const nodeName = node.name ? node.name.split("_")[0] : "";
  useEffect(() => {
    if (open) {
      console.log(node);
      loadPopupChartData(
        node.layer + 1,
        node.data.map((d) => d.ids)
      );
    }
  }, [open, node, loadPopupChartData]);

  const formatSurvivalResponse = () => {
    return popupData.survivalResponse
      ? popupData.survivalResponse
          .map((i) => {
            // console.log(node.value, i);
            switch (i.response) {
              case "Stringent Complete Response":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "SCR",
                  order: 1,
                };
              case "Complete Response":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "CR",
                  order: 2,
                };
              case "Very Good Partial Response":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "VGPR",
                  order: 3,
                };
              case "Partial Response":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "PR",
                  order: 4,
                };
              case "Stable Disease":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "SD",
                  order: 5,
                };
              case "Progressive Disease":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "PD",
                  order: 6,
                };
              case "Unknown":
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                  label: "UNK",
                  order: 7,
                };
              default:
                return {
                  ...i,
                  count: parseInt(i.count),
                  percent: (parseInt(i.count) * 100) / parseInt(node.value),
                };
            }
          })
          .sort((a, b) => a.order - b.order)
      : null;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      // console.log(payload);
      return (
        <div className={classes.tooltip}>
          <p className="label">{`${payload[0].payload.response}`}</p>
          <p className="intro">{`${payload[0].value}`}</p>
          <p className="intro">{`${payload[0].payload.percent.toFixed(2)}%`}</p>
        </div>
      );
    }

    return null;
  };

  if (!node ) return null;

  return (
    <>
      <Backdrop open={active} className={classes.backdrop}>
        <Popper
          id="sankey-popover"
          open={active}
          anchorEl={popoverAnchor.current}
          placement="top-end"
          className={classes.popper}
        >
          {loading ? (
            <Paper elevation={3} className={classes.paper}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item className={classes.item}>
                  {node.name
                    ? `${node.name.split("_")[0]} (${node.value})`
                    : ""}
                </Grid>
                <Grid item className={classes.close}>
                  <IconButton
                    size="small"
                    onClick={() => toggle({ open: false, name: null })}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid container alignItems="center" justify="space-between">
                <Grid item className={classes.item}>
                  <Box
                    className={classes.loadingBox}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <Paper elevation={3} className={classes.paper}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item className={classes.item}>
                  {node.name
                    ? `${node.name.split("_")[0]} (${node.value})`
                    : ""}
                  &nbsp;
                  <Button
                    variant="contained"
                    className={classes.outputButton}
                    onClick={handleCreateCohort}
                  >
                    Create Cohort
                  </Button>
                </Grid>
                <Grid item className={classes.close}>
                  <IconButton
                    size="small"
                    onClick={() => toggle({ open: false, name: null })}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                alignItems="center"
                className={classes.container}
                style={{ dislay: "flex", flexFlow: "row" }}
              >
                <Grid item className={classes.item}>
                  <Typography align="center" variant="h6" gutterBottom>
                    {`Line of Therapy Outcome Response for ${nodeName}`}
                  </Typography>

                  <ResponsiveContainer
                    width={350}
                    minHeight={250}
                    //className={classes.barArea}
                    // ref={barNode}
                  >
                    <BarChart
                      data={formatSurvivalResponse()}
                      margin={{
                        left: -10,
                        bottom: 20,
                      }}
                    >
                      <Tooltip content={<CustomTooltip />} />
                      <XAxis
                        dataKey="label"
                        label={{
                          value: "Outcome",
                          angle: 0,
                          position: "insideBottom",
                          dy: 10,
                          dx: 0,
                        }}
                      />
                      <YAxis
                        dataKey="count"
                        label={{
                          value: "Patients",
                          angle: -90,
                          position: "insideLeft",
                          dy: 20,
                          dx: 14,
                        }}
                      />

                      <CartesianGrid strokeDasharray="3 3" />
                      <Bar dataKey="count" fill={theme.charts[0]} />
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>

                <Divider orientation="vertical" flexItem />

                <Grid item className={classes.sankey}>
                  <SankeyChart
                    data={popupData.sankeyRegimen}
                    title={`Inter-Line of Therapy Treatment Regimens for ${nodeName}`}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Popper>
      </Backdrop>

      <div ref={popoverAnchor} />
      <ChangeName
        className={classes.nameChange}
        open={changeName}
        name={nodeName}
        callback={createCohort}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  ...state.charts.popupCharts,
});

const mapDispatch = {
  cohortAdd,
  loadPopupChartData,
};

export default connect(mapStateToProps, mapDispatch)(PopupChart);
