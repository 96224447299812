import {Cohort} from "../../interface"
import {CohortAction} from "./models"

export const types = {
  COHORTS_REQUEST: 'COHORTS_REQUEST',
  COHORTS_SUCCESS: 'COHORTS_SUCCESS',
  ADD_NEW_COHORT: 'ADD_NEW_COHORT',
  SAVE_COHORT: 'SAVE_COHORT',
  SAVE_COHORT_SUCCESS: 'SAVE_COHORT_SUCCESS',
  REMOVE_COHORT: 'REMOVE_COHORT',
  COHORT_CHANGE: 'COHORT_CHANGE',
  COHORT_SET_ACTIVE: 'COHORT_SET_ACTIVE',
  COHORT_SET_SELECTED: 'COHORT_SET_SELECTED',
  COHORT_CLEAR_SELECTED: 'COHORT_CLEAR_SELECTED',
  COHORT_TOGGLE_SELECTED: 'COHORT_TOGGLE_SELECTED',
  COHORT_ADD_VARIABLE: 'COHORT_ADD_VARIABLE',
  COHORT_REMOVE_VARIABLE: 'COHORT_REMOVE_VARIABLE',
  COHORT_FILTER_CHANGE:'COHORT_FILTER_CHANGE'
}


export const setSelected = (cohort: Cohort): CohortAction => ({
  type: types.COHORT_SET_SELECTED,
  cohort
})

export const clearSelected = (cohort: Cohort): CohortAction => ({
  type: types.COHORT_CLEAR_SELECTED,
  cohort
})

export const toggleSelected = (cohort: Cohort): CohortAction => ({
  type: types.COHORT_TOGGLE_SELECTED,
  cohort
})

export const cohortResquest = (): CohortAction => ({
  type: types.COHORTS_REQUEST
})

export const getCohortsSuccess = (cohorts: Cohort[]): CohortAction => ({
  type: types.COHORTS_SUCCESS,
  cohorts
})

export const cohortAdd = (cohort: Cohort): CohortAction => ({
  type: types.ADD_NEW_COHORT,
  cohort
})

export const saveCohort = (cohort: Cohort): CohortAction => ({
  type: types.SAVE_COHORT,
  cohort
})

export const saveCohortSuccess = (cohort: Cohort): CohortAction => ({
  type: types.SAVE_COHORT_SUCCESS,
  cohort
})

export const cohortRemove = (cohort: Cohort ): CohortAction => ({
    type: types.REMOVE_COHORT,
    cohort
})

export const cohortChanged = ( cohort: Cohort): CohortAction => ({
  type: types.COHORT_CHANGE,
  cohort
})

export const setActive = (cohort: Cohort): CohortAction => ({
  type: types.COHORT_SET_ACTIVE,
  cohort
})

//these functions change the variables and filters on the cohort being actively edited
export const addVariable = (var_key: string, name: string): CohortAction => ({
  type: types.COHORT_ADD_VARIABLE,
  variable: {name, var_key}
})

export const removeVariable = (var_key: string): CohortAction => ({
  type: types.COHORT_REMOVE_VARIABLE,
  variable: {var_key}
})

export const changeFilter = (var_key: string, filter: any): CohortAction => ({
  type: types.COHORT_FILTER_CHANGE,
  variable: {var_key, filter}
})
