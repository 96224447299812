import {ThunkAction} from "redux-thunk"
import {RootState} from "../store"
import {getVariables, variablesFail, variablesSuccess} from "./actions"
import API from "../../api"

type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>

export const getVariableList = (): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        dispatch(getVariables())
        const data = await API.variables.getVariables()
        if (data) {
            dispatch(variablesSuccess(data))
        } else {
            dispatch(variablesFail('Something went wrong'))
            return Promise.reject()
        }
    } catch (e) {
        return Promise.reject()
    }
    return Promise.resolve(true)
}