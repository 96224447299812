import {Cohort} from "../interface"

const API = require('./index')

export const getChartData = async (params: ChartRequestBody) => {
   
    const data = await API.default.client.post('/chart', params)
    return data && data.data;
}

export interface ChartRequestBody {
    chart: ChartType
    line_of_therapy?: number,
    cohorts?: Cohort[],
    ids?: number[]
}

export enum ChartType {
    sankey = 'sankey',
    zoom_sunburst = 'zoom sunburst',
    overall_survival = 'overall_survival',
    progression_free_survival = 'progression_free_survival',
    sunburst = 'sunburst',
    sankey_node = 'sankey_node'
}