import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles, Tooltip
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { signUp } from "../redux/Account/thunks";
import { useHistory } from "react-router";
import { IMaskInput } from "react-imask";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  input: {
    margin: theme.spacing(0, 0, 2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "#ff0000",
  },
  success: {
    color: "#05a000"
  }
}));

const PhoneTextMask = (props) => {
  const { inputRef, ...other } = props;

  return (
    <IMaskInput
      {...other}
      ref={ref => inputRef(ref ? ref.inputElement : null)}
      unmask={true}
      mask={'(000) 000-0000'}
    />
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: theme.palette.common.white,
  }
}))(Tooltip);

const PasswordTooltip = (props) => {
  const classes = useStyles()
  return (
    <div>
      <p className={!props.length ? classes.error : classes.success}>Password must be at least 8 characters long</p>
      <p className={!props.uppercase ? classes.error : classes.success}>Password must have at least one uppercase letter</p>
      <p className={!props.lowercase ? classes.error : classes.success}>Password must have at least one lowercase letter</p>
      <p className={!props.number ? classes.error : classes.success}>Password must have at least one number</p>
    </div>
  )
};

const SignUp = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [username, setUsername] = useState()
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [phone, setPhone] = useState()
  const [passwordTooltip, setPasswordTooltip] = useState(false)
  const [repeatPassTooltip, setRepeatPassTooltip] = useState(false)
  const [matchPasswords, setMatchPasswords] = useState(false)
  const [passwordReqs, setPasswordReqs] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    const redirect = await dispatch(signUp({firstName, lastName, username, password, phone}))
    if(redirect) history.push({pathname: "/verify", state: {username}})
  };

  useEffect(() => {
    password.length >= 8 ?
      setPasswordReqs(state => ({...state, length: true})) :
      setPasswordReqs(state => ({...state, length: false}))
    password.match(/(?=.*[a-z])/) ?
      setPasswordReqs(state => ({...state, lowercase: true})) :
      setPasswordReqs(state => ({...state, lowercase: false}))
    password.match(/(?=.*[A-Z])/) ?
      setPasswordReqs(state => ({...state, uppercase: true})) :
      setPasswordReqs(state => ({...state, uppercase: false}))
    password.match(/(?=.*[0-9])/) ?
      setPasswordReqs(state => ({...state, number: true})) :
      setPasswordReqs(state => ({...state, number: false}))
  }, [password])

  useEffect(() => {
    password.length > 1 && password === repeatPassword ?
      setMatchPasswords(true) : setMatchPasswords(false)
  }, [password, repeatPassword])

  const {loading, errors} = props;

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <p>
          { process.env.REACT_APP_CLIENT_NAME ?
            <img src={ `https://engine-templates.s3.amazonaws.com/${ process.env.REACT_APP_CLIENT_NAME }/logo.svg` }
                 alt={ process.env.REACT_APP_CLIENT_NAME }
                 width={ 210 }/>
            : null }
        </p>
        <p>
          <img src="logo.svg" width={210} alt="Clinical Decision Support" />
        </p>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="phone"
                label="Phone Number"
                type="tel"
                id="phone"
                autoComplete="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                InputProps={{
                  inputComponent: PhoneTextMask
                }}
              />
            </Grid>
            <Grid item xs={12}>

              <LightTooltip
                title={ <PasswordTooltip {...passwordReqs} /> }
                open={passwordTooltip}
                arrow
                placement="right"
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <TextField
                  className={classes.input}
                  type="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => setPasswordTooltip(true)}
                  onBlur={() => setPasswordTooltip(false)}
                />
              </LightTooltip>
              <LightTooltip
                title={ !matchPasswords ? <span className={classes.error}>Passwords doesn't match</span> : <span className={classes.success}>Passwords are matched</span> }
                open={repeatPassTooltip}
                arrow
                placement="right"
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <TextField
                  type="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="repeatPassword"
                  label="Repeat Password"
                  name="repeatPassword"
                  autoComplete="repeatPassword"
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  onFocus={() => setRepeatPassTooltip(true)}
                  onBlur={() => setRepeatPassTooltip(false)}
                />
              </LightTooltip>
            </Grid>
          </Grid>
          {errors ? errors.map((error, key) =>
            <FormHelperText key={key} className={classes.error}>{error.msg}</FormHelperText>
          ) : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading || !matchPasswords}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link
                to="/"
                className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-body2 MuiTypography-colorPrimary"
              >
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  ...state.signUp
})

const mapDispatch = {
  signUp
}

export default connect(mapStateToProps, mapDispatch)(SignUp)