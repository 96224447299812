const axios = require("axios");
const auth = require("./auth");
const variables = require("./variables");
const sql = require("./sql");
const cohorts = require("./cohorts");

const client = axios.create({
  headers: {
    'Access-Control-Allow-Headers': [
      'Accept',
      'Accept-Encoding',
      'Accept-Language',
      'Access-Control-Allow-Headers',
      'Access-Control-Allow-Origin',
      'Connection',
      'Host',
      'Origin',
      'Referer',
      'User-Agent',
      'Content-Type',
      'Authorization'
    ]
  },
  baseURL: process.env.REACT_APP_API_BASEPATH
    ? process.env.REACT_APP_API_BASEPATH
    : "https://eg2l8x61m6.execute-api.us-east-1.amazonaws.com/dev",
});

client.interceptors.request.use(config => {
  if((config.url !== '/auth' && config.url !== '/register') && !config.headers.common['Authorization']) {
    const token = window.localStorage.getItem('token');
    if(!token) {
      window.location.href = '/'
    } else {
      config.headers.common['Authorization'] = token
      return config;
    }
  }
  return config;
}, error => Promise.reject(error))

client.interceptors.response.use(response => response, error => {
  if(error.response && error.response.status === 401) {
    window.location.href = '/'
  }
  return Promise.reject(error)
})

export default {
  client,
  auth,
  cohorts,
  variables,
  sql,
};
