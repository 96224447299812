import clsx from "clsx";
import VariablesList from "./VariablesList";
import { Drawer } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Help from "./Help/Help";
import { CohortFilterDataHelp } from "./Help/HelpStrings";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "100vh",
    height: "calc(100vh - 64px)",
    marginTop: "64px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0,0,0,.4)",
      borderRadius: "10px",
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  title: {
    padding: theme.spacing(1, 2, 0),
    fontSize: 18
  }
}));

const SideBar = () => {
  const classes = useStyles();
  return (
    <div>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper),
        }}
      >
        <Typography variant="h6" className={classes.title}>
          Cohort Filter Data
          <Help top={10} right={8} content={<CohortFilterDataHelp />} />
        </Typography>
        <VariablesList />
      </Drawer>
    </div>
  );
};

export default SideBar;
