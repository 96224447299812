const API = require("./index");

const getSQL = async (query) => {
  const data = await API.default.client.get("/sql", {
    params: {
      query: query,
    },
  });
  return data && data.data;
};

export { getSQL };
