import {Cohort} from "../interface";

const API = require('./index')

export const getAllCohorts = async (userId: number) => {
    const data = await API.default.client.get(`/cohorts/${userId}`)
    return data && data.data;
}

export const saveCohort = async (cohort: Cohort): Promise<Cohort> => {
    const {id, variables, ...rest} = cohort
    const data = await API.default.client.post('/cohort', {...rest, variables: JSON.stringify(variables)})
    return data && data.data;
}

export const updateCohort = async (cohort: Cohort): Promise<Cohort> => {
    const {variables, ...rest} = cohort
    const data = await API.default.client.put(`/cohort/${cohort.id}`, {...rest, variables: JSON.stringify(variables)})
    return data && data.data
}

export const deleteCohort = async (id: number) => {
    const data = await API.default.client.delete(`/cohort/${id}`)
    return data && data.data
}


export const sizeCohort = async (cohort: Cohort): Promise<Cohort> => {
    //console.log("request cohort size")
    const data = await API.default.client.post('/cohortsize', { cohort: JSON.stringify(cohort) })
    return data && data.data;
}
