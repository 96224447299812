import React from 'react';

export const CohortFilterDataHelp = () => {
    return (
        <div>
            <h1>Cohort Filter Data</h1>
            <p>Available variables for cohort selection. Select the variables which will be used for selecting the
                desired patient cohort.</p>
        </div>
    )
}

export const CohortEditorHelp = () => {
    return (
        <div>
            <h1>Creating a New Cohort</h1>
            <p>Follow the steps below for creating a new cohort:</p>
            <ol>
                <li>Select “New cohort”</li>
                <li>Name the new cohort</li>
                <li>Add variable from the “available variables” panel to be added to the new cohort</li>
                <li>Adjust data ranges for each of the added variables as needed</li>
            </ol>
        </div>
    )
}

export const SankeyHelp = () => {
    return (
        <div>
            <h1>Visualizing Treatment Journey </h1>
            <p><b>Sankey diagrams</b> are a type of flow diagram consisting of nodes and links. The nodes represent
                baseline treatment regimens for each line of therapy. The links represent the number of patients that
                transition from one line of therapy to another. Patients flow left to right through each line of
                therapy. “No Therapy” indicates the end of therapy for a patient. The “Other” therapy node includes
                uncommon baseline treatment regimens that are grouped together. </p>
            <br/>
            <p>Clicking on a node will show the detailed treatments within a new Sankey diagram.</p>
            
            <p>Each line of therapy is composed of multiple treatment regimen groups.
            Sub-sets of patients transition through different regimen groups within this single line of therapy. 
            A patient treatment regimen ends when a patient transitions to a “No Therapy” group node.
             Group nodes with 1 patient may be combined into an “Other” group node. </p>
             <br /> 
            <p>Hovering over a group node will display the number of patients who received a particular regimen at a particular group.
            The time duration the patients received a particular regimen is indicated by the “Mid.” (Median), 
            “Low” (5 percentile) and “High” (95 percentile) in days. </p>
            <br/>
            <p>The number of “Groups” displayed can be adjusted with the slider at the bottom of the chart to help with readability and focus. </p>
            <br /> 
            <p>Drug Key:</p>
            <table >
                <thead>
                    <tr>
                        <th>Drug</th>
                        <th>Abv.</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Bendamustine</td>
                    <td>Bend</td>
                </tr>
                <tr>
                    <td>Bortezomib</td>
                    <td>Bor</td>
                </tr>
                <tr>
                    <td>Busulphan</td>
                    <td>Bus</td>
                </tr>
                <tr>
                    <td>Carfilzomib</td>
                    <td>Car</td>
                </tr>
                <tr>
                    <td>Biaxin</td>
                    <td>Clar</td>
                </tr>
                <tr>
                    <td>Cyclophosphamide</td>
                    <td>Cyc</td>
                </tr>
                <tr>
                    <td>Daratumumab</td>
                    <td>Dar</td>
                </tr>
                <tr>
                    <td>Dexamethasone</td>
                    <td>Dex</td>
                </tr>
                <tr>
                    <td>Doxorubicin</td>
                    <td>Dox</td>
                </tr>
                <tr>
                    <td>Elotuzumab</td>
                    <td>Elo</td>
                </tr>
                <tr>
                    <td>Fludarabine</td>
                    <td>Flu</td>
                </tr>
                <tr>
                    <td>Ixazomib</td>
                    <td>Ixa</td>
                </tr>
                <tr>
                    <td>Lenalidomide</td>
                    <td>Len</td>
                </tr>
                <tr>
                    <td>Melphalan</td>
                    <td>Mel</td>
                </tr>
                <tr>
                    <td>Methotrexate</td>
                    <td>MTX</td>
                </tr>
                <tr>
                    <td>Nivolumab</td>
                    <td>Niv</td>
                </tr>
                <tr>
                    <td>Oprozomib</td>
                    <td>Opro</td>
                </tr>
                <tr>
                    <td>Panobinostat</td>
                    <td>Pano</td>
                </tr>
                <tr>
                    <td>Pomalidomide</td>
                    <td>Pom</td>
                </tr>
                <tr>
                    <td>Prednisone</td>
                    <td>Pred</td>
                </tr>
                <tr>
                    <td>Rituxan</td>
                    <td>Rit</td>
                </tr>
                <tr>
                    <td>Stem Cell Transplant</td>
                    <td>SCT</td>
                </tr>
                <tr>
                    <td>Selinexor</td>
                    <td>Sel</td>
                </tr>
                <tr>
                    <td>Thalidomide</td>
                    <td>Thal</td>
                </tr>
                <tr>
                    <td>Vincristine</td>
                    <td>Vin</td>
                </tr>
                <tr>
                    <td>Vorinostat</td>
                    <td>Vor</td>
                </tr>
                </tbody>
            </table>
            
        </div>
    )
}

export const OverallSurvivalHelp = () => {
    return (
        <div>
            <h1>Overall Survival</h1>
            <p><b>Overall Survival (OS)</b>, shows the time from initial treatment to censored death </p>
            <br/>
            <p><a href="https://en.wikipedia.org/wiki/Survival_rate">More Information</a></p>
        </div>
    )
}

export const ProgressionFreeSurvivalHelp = () => {
    return (
        <div>
            <h1>Progression-Free Survival</h1>
            <p><b>Progression-Free Survival (PFS)</b>, shows the time from initial treatment to first censored relapse</p>
            <br/>
            <p><a href="https://en.wikipedia.org/wiki/Progression-free_survival">More Information</a></p>
        </div>
    )
}

export const ZoomSunburstHelp = () => {
    return (
        <div>
            <h1>The Sunburst Diagram</h1>
            <p><b>The Sunburst Diagram</b> is used to visualize treatment regimens by line of therapy, depicted by concentric circles. The circle in the center represents all selected patients, with the hierarchy moving outward from the center. A treatment regimen bears a longitudinal relationship to those regimens of the outer circle which lie within the angular sweep of the selected regimen.</p>
            <br/>
            <p>Mouse over area to see, more detail.  Click to zoom into treatment</p>
        </div>
    )
}


export const ChartLayoutHelp = () => {
    return (
        <div>
            <h1>Cohort and Chart Selection</h1>
            <p>Use the drop down menu to select the cohorts to graph. Then use buttons to select which graphs to show.</p>
        </div>
    )
}