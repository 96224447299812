import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  FormHelperText,
  TextField,
  makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { signUpResend, signUpVerify } from "../redux/Account/thunks";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "#ff0000",
  },
}));

const SignUpVerify = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [code, setCode] = useState()
  const [username, setUsername] = useState()
  const [resend, setResend] = useState(false)
  const [count, setCount] = useState(90)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const redirect = await dispatch(signUpVerify(username, code))
    if(redirect) history.push("/success")
  };

  const handleResend = async (e) => {
    e.preventDefault()
    await dispatch(signUpResend(username))
    setResend(true)
  }

  const {loading, errors} = props;

  useEffect(() => {
    setUsername(props?.history?.location?.state?.username)
  }, [props.history])

  useEffect(() => {
    let time;
    if(resend && count > 0) {
      time = setTimeout(() => setCount(c => c - 1), 1000)
    }
    if(resend && count === 0) {
      setResend(false);
      setCount(90);
    }
    return () => clearTimeout(time)
  }, [resend, count])

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <p>
          { process.env.REACT_APP_CLIENT_NAME ?
            <img src={ `https://engine-templates.s3.amazonaws.com/${ process.env.REACT_APP_CLIENT_NAME }/logo.svg` }
                 alt={ process.env.REACT_APP_CLIENT_NAME }
                 width={ 210 }/>
            : null }
        </p>
        <p>
          <img src="logo.svg" width={210} alt="Clinical Decision Support" />
        </p>
        <Typography component="h1" variant="h5">
          Verify your e-mail
        </Typography>
        <Typography component="p" align="center">
          Check you e-mail and insert the code your received to verify it
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="code"
                label="Verification Code"
                name="code"
                autoComplete="code"
                onChange={(e) => setCode(Number(e.target.value))}
              />
            </Grid>
          </Grid>
          {errors ? errors.map((error, key) =>
            <FormHelperText key={key} className={classes.error}>{error.msg}</FormHelperText>
          ) : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Verify
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Typography>
                { resend ?
                  <span>Check your e-mail for a new code ({ count })</span> :
                  <span>Didn't receive a code?&nbsp;<Link onClick={ handleResend } to="#">Resend it</Link></span> }
              </Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  ...state.signUpVerify
})

const mapDispatch = {
  signUpVerify,
  signUpResend
}

export default connect(mapStateToProps, mapDispatch)(SignUpVerify)