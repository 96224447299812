import {types} from "./actions";
import {VariablesAction, VariablesState} from "./models";

const initState: VariablesState = {
    loading: false,
    variables: []
}

export const variablesReducer = (state = initState, action: VariablesAction): VariablesState => {
    switch (action.type) {
        case types.VARIABLES_REQUEST:
            return {
                loading: true,
                variables: null
            }
        case types.VARIABLES_SUCCESS:
            return {
                loading: false,
                variables: action.variables
            }
        case types.VARIABLES_FAIL:
            return {
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}