import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUpSuccess from "./pages/SignUpSuccess";
import Dashboard from "./pages/Dashboard";
import store from "./redux/store";
import SignUpVerify from "./pages/SignUpVerify";
import SignInForgotPassword from "./pages/SignInForgotPassword";

const defaultTheme = window.EE && window.EE.theme ? window.EE.theme : {};
const defaultChartColors = window.EE && window.EE.charts ? window.EE.charts : [
  "#1f77b4",
  "#2ca02c",
  "#ff7f0e",
  "#a92727",
  "#4db6ac",
  "#82488c",
  "#a84466"
];

const theme = createMuiTheme({...defaultTheme, charts: defaultChartColors});

ReactDOM.render(
  <Provider store={ store }>
    <ThemeProvider theme={ theme }>
      <CssBaseline/>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={ SignIn }/>
          <Route exact path="/signup" component={ SignUp }/>
          <Route exact path="/verify" component={ SignUpVerify }/>
          <Route exact path="/success" component={ SignUpSuccess }/>
          <Route exact path="/forgot" component={ SignInForgotPassword }/>
          <Route exact path="/dashboard" component={ Dashboard }/>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
