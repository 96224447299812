import {ThunkAction} from "redux-thunk"
import {RootState} from "../store"
import {Cohort} from "../../interface"
import { cohortRemove, cohortResquest, getCohortsSuccess, saveCohortSuccess} from "./actions"
import API from "../../api"
import * as _ from 'lodash';

type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>

export const getCohortsList = (userId: number): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        dispatch(cohortResquest())
        const cohorts: Cohort[] = await API.cohorts.getAllCohorts(userId)

        _.forEach(cohorts, cohort => {
            cohort.uid = cohort.id;
            cohort.dirty = false;
            cohort.selected = false;
        });
        
        //console.log('getCohortList: ', cohorts)
        dispatch(getCohortsSuccess(cohorts))
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const saveCohortChanges = (userId: number, cohort: Cohort): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        const newCohort = cohort.id <= 0 ?
            await API.cohorts.saveCohort({...cohort, user_id: userId}) :
            await API.cohorts.updateCohort({ ...cohort, user_id: userId })
        newCohort.dirty = false;
        newCohort.uid=cohort.uid
        cohort.id <= 0 ?
            dispatch(saveCohortSuccess({...newCohort, variables: JSON.parse(newCohort.variables as any)})) :
            dispatch(saveCohortSuccess(newCohort))
        
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const cohortDelete = (cohort: Cohort): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        if(cohort.id >= 0 ) await API.cohorts.deleteCohort(cohort.id as number)
        dispatch(cohortRemove(cohort))
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}