import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

const NewCohort = ({ open, toggle }) => {
  const [loading, setLoading] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const handleSave = () => {
    setLoading((s) => !s);
    toggle(newTitle);
    setLoading((s) => !s);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onEnter={() => {
        setNewTitle("Unnamed Cohort");
      }}
      open={open}
      onClose={() => { toggle(null) }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create New Cohort</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="cohortName"
          label="Cohort Name"
          type="text"
          value={newTitle}
          onChange={(e) => {
            setNewTitle(e.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggle(null)} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={loading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewCohort;
