import React from "react";
import CohortChart from "./CohortChart";
import { getVariableData } from "../data/data";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingBox: {
    height: "10rem",
  },
}));

/*
    var_key ='xxxx' where 'xxxx' is the unique var_key in variables table of database
    color = color to draw chart
    filter =[0, 10] or ['a', 'b', 'c'] // this is the current active filter setting on data
    onChange  //this is callback with is called with filter and type  as parameters on filter change. 
    onRemove  //this is called when this chart is removed
*/
function CohortData({
  var_key,
  color,
  filter,
  onChange,
  onRemove,
  activeCohort,
}) {
  const [data, setData] = React.useState(null);

  const classes = useStyles();

  React.useEffect(() => {
    const getData = async () => {
      let x = await getVariableData(var_key, activeCohort.ids);
      x = { ...x, var_key };
      setData(x);
    };

    if (!data || var_key !== data.var_key) {
      getData();
    }
  });

  if (!var_key) {
    console.log("ERROR Cohort Data called with no var_key");
    return "";
  }

  if (!data || var_key !== data.var_key)
    return (
      <Box
        className={classes.loadingBox}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  function myChange(x) {
    if (onChange) {
      onChange(data.type, x);
    }
  }
  // console.log(data);
  return (
    <CohortChart
      value={data}
      color={color}
      filter={filter}
      onChange={myChange}
      onRemove={onRemove}
    />
  );
}

export default CohortData;
