const API = require('./index')

const getVariables = async () => {
  const data = await API.default.client.get(`/variables`);
  return data && data.data;
}

const getVariable = async (var_key: string, person_ids: number[]=[]) => {
  const data = await API.default.client.get(`/variable?var_key=${var_key}&person_ids=${person_ids}`);
  return data && data.data;
}

export {
  getVariables,
  getVariable
}