import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import ChangedCohort from "./dialogs/ChangedCohort";
import SaveCohort from "./dialogs/SaveCohort";
import DeleteCohort from "./dialogs/DeleteCohort";
import NewCohort from "./dialogs/NewCohort";
import IconButton from "@material-ui/core/IconButton";
import { DeleteForever, Save } from "@material-ui/icons";
import { CohortEditorHelp } from "./Help/HelpStrings";
import Help from "./Help/Help";
import * as _ from "lodash";
import defaultCohort from "../data/defaultCohort";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  chips: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  hidden: {
    display: "none",
  },
  right: {
    textAlign: "right",
  },
}));

const CohortEditor = ({
  activeCohort,

  //redux states
  cohorts,
  cohortsLoading,

  //redux actions
  saveCohortChanges,
  cohortDelete,
  setActive,
  cohortAdd,
}) => {
  const classes = useStyles();
  const [noCohorts, setNoCohorts] = React.useState(false);
  const [dirtySave, setDirtySave] = React.useState(false);
  const [newCohort, setNewCohort] = React.useState(false);
  const [saveCohort, setSaveCohort] = React.useState(false);
  const [saveDirtyCohort, setSaveDirtyCohort] = React.useState(false);
  const [deleteCohort, setDeleteCohort] = React.useState(false);

  const [newCohorid, setNewCohorid] = React.useState(0);

  //check and see if we have loaded a cohort from database
  React.useEffect(() => {
    //console.log(cohortsLoading, cohorts);

    if (cohortsLoading === false) {
      if (!cohorts || cohorts.length === 0) {
        //if no cohort set state that opens dialog to let user create a sample cohort
        setNoCohorts(true);
      }
    }
  }, [cohortsLoading, cohorts]);

  const handleChange = (e) => {
    setNewCohorid(e.target.value);
    if (activeCohort.dirty) {
      setDirtySave(true);
    } else {
      const active = cohorts.filter((item) => item.uid === e.target.value)[0];
      setActive(active);
    }
  };

  const handleDirtySaveChanges = (state) => {
    setDirtySave(false);
    if (state) {
      setSaveDirtyCohort(true);
    } else {
      const active = cohorts.filter((item) => item.uid === newCohorid)[0];
      setActive(active);
    }
  };

  const handleSaveCohortDirty = (userId, name) => {
    if (userId) {
      saveCohortChanges(userId, { ...activeCohort, name });
    }
    const active = cohorts.filter((item) => item.uid === newCohorid)[0];
    setActive(active);
    setSaveDirtyCohort((s) => !s);
  };

  const handleDeleteCohort = (deleted, cohort) => {
    if (deleted) {
      cohortDelete(cohort);
    }
    setDeleteCohort((s) => !s); //turn off dialog box
  };

  const handleNewCohort = (name) => {
    // console.log(name);
    if (name) {
      const newCohort = {
        name,
        variables: [],
      };
      //console.log(name);
      cohortAdd(newCohort);
      // if (cohorts.length === 0) {

      //   cohortAdd(newCohort);
      //   // toggleSelection(c); Alex changed the cohortAdd so it toggles the cohort on, so this function was now turning off.
      //   //loadChart(chart);
      // } else {
      //   cohortAdd(newCohort);
      // }
    }
    setNewCohort((s) => !s); //turn off dialog box
  };

  const handleSaveCohort = (userId, name) => {
    if (userId) {
      saveCohortChanges(userId, { ...activeCohort, name });
    }
    setSaveCohort((s) => !s);
  };

  function newCohortButton() {
    setNewCohort((s) => !s);
  }

  function saveCohortButton() {
    setSaveCohort((s) => !s);
  }

  function deleteCohortButton() {
    setDeleteCohort((s) => !s);
  }

  return (
    <div>
      <Dialog open={noCohorts}>
        <DialogTitle id="customized-dialog-title">No User Cohorts</DialogTitle>
        <DialogContent dividers>
          <Button
            variant="contained"
            className={classes.outputButton}
            onClick={() => {
              const c = _.cloneDeep(defaultCohort);
              c.name = "Sample";
              cohortAdd(c);
              // toggleSelection(c); Alex changed the cohortAdd so it toggles the cohort on, so this function was now turning off.
              setNoCohorts(false);
            }}
          >
            Create Sample Cohort
          </Button>
          <Button
            variant="contained"
            className={classes.outputButton}
            onClick={() => {
              handleNewCohort("");
              setNoCohorts(false);
            }}
          >
            Create New Empty Cohort
          </Button>
        </DialogContent>
      </Dialog>

      <Paper className={classes.paper}>
        <Help content={<CohortEditorHelp />} />
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={newCohortButton}
                >
                  New Cohort
                </Button>
              </Grid>
              {activeCohort ? (
                <Grid item>
                  <IconButton
                    variant="contained"
                    color="primary"
                    title="Save Cohort"
                    onClick={saveCohortButton}
                    className={activeCohort.dirty ? null : classes.hidden}
                  >
                    <Save />
                  </IconButton>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    title="Delete Cohort"
                    onClick={deleteCohortButton}
                    className={null} //{activeCohort.id <= 0 ? classes.hidden : null}
                  >
                    <DeleteForever />
                  </IconButton>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              {activeCohort &&
              !_.isEmpty(activeCohort) &&
              cohorts.filter((x) => x.uid === activeCohort.uid).length > 0 ? (
                <Select
                  id="cohort-select"
                  value={activeCohort.uid}
                  onChange={handleChange}
                >
                  {cohorts.map((c, key) => {
                    return (
                      <MenuItem key={key} value={c.uid}>
                        {c.dirty ? c.name + " *" : c.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {activeCohort.dirty ? "* Cohort not Saved" : ""}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <ChangedCohort
        open={dirtySave}
        cohort={activeCohort}
        callback={handleDirtySaveChanges}
      />
      <SaveCohort
        open={saveCohort}
        cohort={activeCohort}
        toggle={handleSaveCohort}
      />
      <SaveCohort
        open={saveDirtyCohort}
        cohort={activeCohort}
        toggle={handleSaveCohortDirty}
      />
      <DeleteCohort
        open={deleteCohort}
        cohort={activeCohort}
        toggle={handleDeleteCohort}
      />
      <NewCohort open={newCohort} toggle={handleNewCohort} />
    </div>
  );
};

export default CohortEditor;
