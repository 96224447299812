import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  makeStyles,
  useTheme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import CohortEditor from "../components/CohortEditor";
import CohortData from "../components/CohortData";

import {
  cohortDelete,
  getCohortsList,
  saveCohortChanges,
} from "../redux/Cohorts/thunks";
import { connect } from "react-redux";
import { getVariableList } from "../redux/Variables/thunks";
import { changeChart } from "../redux/Charts/actions";
import {
  changeFilter,
  cohortAdd,
  removeVariable,
  toggleSelected,
  setActive,
} from "../redux/Cohorts/actions";
import { loadChartsData } from "../redux/Charts/thunks";
import API from "../api";
import ChartsLayout from "../components/charts/ChartsLayout";

import * as _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    minHeight: "calc(100% - 64px)",
    "& > .MuiGrid-container": {
      height: "calc(100vh - 64px - 48px + 8px)",
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paperFullHeight: {
    height: "100%",
    minHeight: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0,0,0,.4)",
      borderRadius: "10px",
    },
  },
  charts: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
  },
  fullHeight: {
    flexFlow: "column",
    alignItems: "stretch",
    height: "100%",
  },
  fullContainerHeight: {
    height: "calc(100% + 16px)",
  },
  sidebarFullHeight: {
    flexBasis: "100%",
    overflow: "hidden",
  },
  outputFullHeight: {
    flexBasis: "calc(100vh - 64px + 8px - 124px)",
    overflow: "hidden",
  },
  heightAuto: {
    flex: "0 1 auto",
  },
  outputButton: {
    borderRadius: "5rem",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: "48px",
  },
  formControl: {
    minWidth: 300,
    maxWidth: 300,
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    chart,
    getCohortsList,
    loadChartsData,
    getVariableList,
    removeVariable,
    changeFilter,
    setActive,
    cohorts,
    active_uid,
    cohortsLoading,
    cohortAdd,
    saveCohortChanges,
    cohortDelete,
  } = props;

  //get the cohort actively being edited
  let activeCohort = {};
  const index = cohorts.findIndex((c) => c.uid === active_uid);
  if (index > -1) {
    activeCohort = cohorts[index];
  } else {
    if (cohorts[0]) {
      setActive(cohorts[0]);
    }
  }

  //load the defaults
  useEffect(() => {
    const getCohorts = async () => {
      const userString = await window.localStorage.getItem("session");
      const user =
        userString && userString.length ? JSON.parse(userString) : null;
      getCohortsList(user.id);
    };
    getVariableList();
    getCohorts();
  }, []);

  const loadChart = (type) => {
    const selectedCohorts = _.filter(cohorts, (c) => {
      return c.selected;
    });
    // console.log(selected)
    // const index = selected
    //   ? selectedCohorts.findIndex((s) => s.id === selected.id)
    //   : -1;
    // let updatedSelected = [...selectedCohorts];

    // if (index > -1) {
    //   updatedSelected[index] = selected;
    // }
    //console.log("Loading charts", selectedCohorts);
    loadChartsData(type, selectedCohorts);
  };

  const [cohortSize, setCohortSize] = useState(0);

  const handleCohortRemove = (var_key) => {
    removeVariable(var_key);
  };

  let brushTimer = null;
  const handleCohortChange = (var_key, type, filter) => {
    if (brushTimer) {
      // use brushTimer to simulate onMouseUp event because Brush doesn't have it
      clearTimeout(brushTimer);
    }
    brushTimer = setTimeout(() => {
      changeFilter(var_key, filter);
    }, 800);
  };

  useEffect(() => {
    const update = async () => {
      // console.log(activeCohort, chart);
      const size = await API.cohorts.sizeCohort(activeCohort);
      setCohortSize(size);
      loadChart(chart);
    };
    if (activeCohort && !_.isEmpty(activeCohort)) update();
  }, [activeCohort, chart]);

  //console.log(noCohorts);
  return (
    <div className={classes.root}>
      <NavBar />
      <SideBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={2}>
            <Grid item sm={3} className={classes.fullContainerHeight}>
              <Grid container spacing={2} className={classes.fullHeight}>
                <Grid item xs={12} className={classes.heightAuto}>
                  <CohortEditor
                    activeCohort={activeCohort}
                    cohortsLoading={cohortsLoading}
                    saveCohortChanges={saveCohortChanges}
                    cohortDelete={cohortDelete}
                    setActive={setActive}
                    cohortAdd={cohortAdd}
                    cohorts={cohorts}
                  />
                </Grid>
                <Grid item xs={12} className={classes.sidebarFullHeight}>
                  <Paper
                    className={clsx(classes.paper, classes.paperFullHeight)}
                  >
                    <div className={classes.charts}>
                      <Typography variant="h6" gutterBottom>
                        Selected Cohort Variables
                      </Typography>
                      {activeCohort && "Patients in Cohort: " + cohortSize}
                      {activeCohort &&
                        activeCohort.variables &&
                        activeCohort.variables.map((variable, key) => {
                          return (
                            <CohortData
                              color={theme.charts[key]}
                              key={variable.var_key}
                              var_key={variable.var_key}
                              filter={variable.filter}
                              activeCohort={activeCohort}
                              onRemove={() =>
                                handleCohortRemove(variable.var_key)
                              }
                              onChange={(type, filter) =>
                                handleCohortChange(
                                  variable.var_key,
                                  type,
                                  filter
                                )
                              }
                            />
                          );
                        })}
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={9} className={classes.fullContainerHeight}>
              <ChartsLayout />
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.charts,
  ...state.cohorts,
  ...state.active_uid,
});

const mapDispatch = {
  getVariableList,
  changeChart,
  loadChartsData,
  getCohortsList,
  removeVariable,
  changeFilter,
  toggleSelected,
  cohortAdd,
  saveCohortChanges,

  cohortDelete,
  setActive,
};

export default connect(mapStateToProps, mapDispatch)(Dashboard);
