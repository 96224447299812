import {Title, VariablesAction} from "./models";

export const types = {
    VARIABLES_REQUEST: 'VARIABLES_REQUEST',
    VARIABLES_SUCCESS: 'VARIABLES_SUCCESS',
    VARIABLES_FAIL: 'VARIABLES_FAIL'
}

export const getVariables = (): VariablesAction => ({
    type: types.VARIABLES_REQUEST
})

export const variablesSuccess = (variables: Title[]): VariablesAction => ({
    type: types.VARIABLES_SUCCESS,
    variables
})

export const variablesFail = (error: string): VariablesAction => ({
    type: types.VARIABLES_FAIL,
    error
})