import {Cohort} from "../interface"

const defaultCohort: Cohort = {
    id: -1,
    uid: -1,
    dirty: true,
    selected: true,
    name: "Default Cohort",
    variables: [
        {name: "Age", var_key: "age", filter: [56, 90]},
        {name: "Ethnicity", var_key: "ethnicity", filter: ["Not Hispanic or Latino"]},
        // {name: "Calcium (mmol/L)", var_key: "calcium", filter: [1.4, 4.3]},
        {name: "Gender", var_key: "gender", filter: ['MALE']}
    ]
};

export default defaultCohort

