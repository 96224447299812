import React from "react";
import {
  BarChart,
  CartesianGrid,
  Tooltip,
  Brush,
  Bar,
  XAxis,
  YAxis,
} from "recharts";
import { Typography, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Close } from "@material-ui/icons";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";

/*
    value is an object with the following field examples
    value = {
        id: 1 //unique number of this cohort variable
        type: 'discrete' //type of data 'discrete', 'continuous', 'integer'
        name: 'Age' //title for the plot
        data: [{x: 1, y:1}, {x: 2, y: 2}] //array of objects with x and y pairs
        units: 'mg' //optional units of x data

    }
    color = color to draw chart
    filter =[0, 10] 
    onChange  //this is callback with is called with filter on filter change. 
    onRemove  //this is called when this chart is removed
    
*/
function NumericCohortChart({ value, color, filter, onChange, onRemove }) {
  const [newFilter, setNewFilter] = React.useState(null);
  const [keyValue, setKeyValue] = React.useState(null);
  const [data, setData] = React.useState(null);

  //console.log(value);
  React.useEffect(() => {
    if (!value) {
      return;
    }
    // let hist = [];
    // for (let i = 0; i < value.hist.bins.length; i++) {
    //   hist[i] = { x: value.hist.bins[i], y: value.hist.histogram[i] };
    // }

    //console.log(filter, hist);
    //let x = hist.filter((d) => filter.includes(d.x));
    //console.log(hist);
    setData(value.hist);
  }, [value]);

  //console.log(value);
  function change(index) {
    var ret = [];
    ret[0] = value.hist[index[0]]["bin"];
    ret[1] = value.hist[index[1]]["bin"];

    if (!newFilter || newFilter[0] !== ret[0] || newFilter[1] !== ret[1]) {
      setNewFilter(ret);

      if (onChange) {
        onChange(ret);
      }
    }
  }

  function remove() {
    if (onRemove) {
      onRemove();
    }
  }

  function getMinIndex(filter, data) {
    if (!filter || !data) return 0;

    const x = data.map((d) => parseFloat(d.bin));
    const y = parseFloat(filter[0]);
    if (y < Math.min(...x) || y > Math.max(...x)) {
      console.log(value, x, filter, y);
      console.log("Filter is out of data range setting to minimal");
      return 0;
    }
    const ix = data.findIndex((d) => parseFloat(d.bin) >= parseFloat(y));
    return ix;
  }

  function getMaxIndex(filter, data) {
    if (!filter || !data) return 0;

    const x = data.map((d) => parseFloat(d.bin));
    let y = parseFloat(filter[1]);
    if (y < Math.min(...x) || y > Math.max(...x)) {
      //console.log("Filter is out of data range setting to minimal", x, y);
      y = Math.max(...x);
    }
    const ix = data.findIndex((d) => parseFloat(d.bin) >= parseFloat(y));
    return ix;
  }

  React.useEffect(() => {
    if (Array.isArray(filter)) {
      setNewFilter(filter);
      setKeyValue(Math.random());
    }
  }, [filter]);

  //console.log(value, data);
  if (!data || data === null) return null;
  return (
    <div key={value.id}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Typography variant="h6">{value.name}</Typography>
        </Grid>
        <Grid item>
          <IconButton size="small" aria-label="delete" onClick={remove}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <ResponsiveContainer width="100%" height={170}>
        <BarChart
          key={keyValue}
          data={data}
          margin={{
            top: 10,
            right: 10,
            bottom: 30, //leave room for slider and units
            left: 30,
          }}
        >
          <Tooltip />

          <XAxis
            dataKey="bin"
            angle={0}
            textAnchor="end"
            interval={null}
            scale={value.type === "continuous" ? "band" : "auto"}
            label={
              value.units
                ? {
                    value: value.units,
                    offset: 0,
                    position: "insideBottom",
                    dy: 35,
                  }
                : null
            }
          />
          <YAxis
            width={30}
            label={{
              value: "count",
              angle: -90,
              position: "insideLeft",
              dy: 10,
              dx: -18,
            }}
          />
          <Bar dataKey="count" fill={color} />

          <CartesianGrid strokeDasharray="3 3" />

          <Brush
            dataKey="bin"
            leaveTimeOut={500}
            onChange={(e) => {
              change([e.startIndex, e.endIndex]);
            }}
            height={20}
            stroke="#000000"
            y={120}
            startIndex={getMinIndex(filter, data)}
            endIndex={getMaxIndex(filter, data)}
          >
            <BarChart>
              <Bar dataKey="count" fill={color} />
            </BarChart>
          </Brush>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default NumericCohortChart;
