import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default function SignUpSuccess() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <p>
          { process.env.REACT_APP_CLIENT_NAME ?
            <img src={ `https://engine-templates.s3.amazonaws.com/${ process.env.REACT_APP_CLIENT_NAME }/logo.svg` }
                 alt={ process.env.REACT_APP_CLIENT_NAME }
                 width={ 210 }/>
            : null }
        </p>
        <p>
          <img src="logo.svg" width={210} alt="Clinical Decision Support" />
        </p>
        <Typography className={classes.title} component="h1" variant="h5">
          Sign up success
        </Typography>
        <Typography align="center" variant="body1">
          Thanks for showing interest in using Clinical Decision Support.
          Please contact administrator to get your account approved.
        </Typography>
      </div>
    </Container>
  );
}
