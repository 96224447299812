import {types} from './actions'
import {CohortAction, CohortState} from "./models";
import {Cohort, CohortFilter} from "../../interface";
import * as _ from 'lodash';

const initState: CohortState = {
    cohorts: [],
    active_uid: 0,
    cohortsLoading: true
}

let newCohortIndex = -2;

export const cohortsReducer = (state = initState, action: CohortAction): CohortState => {
    let newState = _.cloneDeep(state)
    let index:number=0
    const cohort: Cohort=action.cohort as Cohort
    switch (action.type) {    
        case types.COHORTS_REQUEST:
            newState.cohortsLoading = true
            return newState
        case types.COHORTS_SUCCESS:
            newState.cohortsLoading = false
            if (!action.cohorts || action.cohorts.length === 0) {
                return newState;
            }
            action.cohorts.map((item) => {
                let index = newState.cohorts.findIndex(x => x.id === item.id);
                if (index === -1) {
                    newState.cohorts.push(item)
                }
                return null;
            })
        
            if (newState.active_uid===0) {
                newState.active_uid= newState.cohorts[0].uid
            }

            //set at least one cohort as selected
            const c = newState.cohorts.filter((c) => c.selected);

            if (c.length===0) {
                //console.log(newState.cohorts[0])
                if (newState.cohorts[0]) {
                    newState.cohorts[0].selected = true;
                }
            }
            return newState

        case types.ADD_NEW_COHORT:
            cohort.id = newCohortIndex;
            cohort.uid = newCohortIndex
            cohort.selected = true;  //make this selected for graphs
            newCohortIndex = newCohortIndex - 1; 
            cohort.dirty = true;
            newState.cohorts.push(cohort)
            newState.active_uid = cohort.uid
            return newState

        case types.SAVE_COHORT_SUCCESS:
            //console.log(cohort)
            let {ids, ...rest} = action.cohort as Cohort
            let newCohort = rest
            newCohort.dirty = false;
            //console.log(newCohort)
            if (ids !== null) newCohort = { ...rest, ids } as Cohort
            let selectedIndex = newState.cohorts.findIndex(c => c.id === action.cohort?.id)
            selectedIndex = selectedIndex > -1 ? selectedIndex : newState.cohorts.findIndex(c => c.uid === action.cohort?.uid)
            newCohort.selected = newState.cohorts[selectedIndex].selected; //copy selected state 
           // console.log(newState)
            //console.log(selectedIndex)
            newState.cohorts[selectedIndex] = newCohort
            //newState.active_uid = newCohort.uid 
            //console.log(newState)
            return newState

        case types.REMOVE_COHORT:
            const deletedIndex = newState.cohorts.findIndex(c => c.uid === action.cohort?.uid)
            newState.cohorts.splice(deletedIndex, 1)

            //make sure at least one cohort is active in edit pane
            if (newState.cohorts[0]) {
                newState.active_uid = newState.cohorts[0].uid
            }

            //make sure at least one cohort is selected for graphs
            let s=false
            _.forEach(newState.cohorts, (x) => { if (x.selected) { s = true } })
            if (!s) {
                if (newState.cohorts[0]) {
                    newState.cohorts[0].selected = true;
                }
            }

            return newState

        case types.COHORT_SET_ACTIVE:
            //console.log(cohort)
            newState.active_uid=cohort.uid
            return newState
        
        case types.COHORT_SET_SELECTED:
             index = newState.cohorts.findIndex(c => c.uid === action.cohort?.uid)
            if (index > -1)
            {
                newState.cohorts[index].selected = true;
            } else
            {
                console.log("ERROR trying to select invalid cohort", action.cohort)
                }
            return newState
         case types.COHORT_CLEAR_SELECTED:
            index = newState.cohorts.findIndex(c => c.uid === action.cohort?.uid)
            if (index > -1)
            {
                newState.cohorts[index].selected = false;
            } else
            {
                console.log("ERROR trying to clear selection on invalid cohort", action.cohort)
                }
            return newState
        case types.COHORT_TOGGLE_SELECTED:
            index = newState.cohorts.findIndex(c => c.uid === action.cohort?.uid)
            if (index > -1)
            {
                newState.cohorts[index].selected = !newState.cohorts[index].selected;
            } else
            {
                console.log("ERROR trying to toggle selection on invalid cohort", action.cohort)
                }
            return newState
        
        case types.COHORT_ADD_VARIABLE:
            index = newState.cohorts.findIndex(c => c.uid === newState.active_uid)
            if (index > -1) {
                newState.cohorts[index] = {
                    ...newState.cohorts[index],
                    dirty: true,
                    variables: [...newState.cohorts[index].variables as CohortFilter[], action.variable as CohortFilter] 
                }
            }else{
                console.log("ERROR trying to change varaiable", newState.active_uid)
            }
            return newState

        case types.COHORT_REMOVE_VARIABLE:
            index = newState.cohorts.findIndex(c => c.uid === newState.active_uid)
            if (index <= -1) {
                console.log("ERROR getting active cohort", newState.active_uid)
                return newState;
            }
            const newFilter = newState.cohorts[index].variables?.filter(v => v.var_key !== action.variable.var_key)
            newState.cohorts[index] = {
                ...newState.cohorts[index],
                dirty: true,
                variables: newFilter
            }
            return newState

        case types.COHORT_FILTER_CHANGE:
            index = newState.cohorts.findIndex(c => c.uid === newState.active_uid)
            if (index <= -1) {
                console.log("ERROR getting active cohort", newState.active_uid)
                return newState;
            }
            const changedFilter = newState.cohorts[index].variables?.map(
                f => (f.var_key === action.variable.var_key ? {...f, filter: action.variable.filter} : f)
            )
            newState.cohorts[index].variables = changedFilter as CohortFilter[]
            newState.cohorts[index].dirty = true;
            return newState
        default:
            //console.log("ERROR")
            return state
    }
}
