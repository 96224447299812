import API from "../api";
import {VariableId, PersonIds, VariableData} from "../interface"

const _ = require("lodash");

const sqlQuery = async (query:string) => {
  if (!query) {
    console.log("Error query is empty");
    return null;
  }
  try {
    return await API.sql.getSQL(query).then((data) => data);
  } catch (err) {
    console.log(err);
    console.log(query);
    return null;
  }
};

export async function getVariableData(var_key: VariableId, person_ids: number[] = []): Promise<VariableData> {
  const data = await API.variables.getVariable(var_key,person_ids);
  //console.log(data)
  return data
}

const fetchFilteredPersonIds = async (cohortVar:any) => {
  //console.log(cohortVar);
  let variable = await getVariableEntry(cohortVar.var_key);
  let query;
  if (
    variable &&
    variable[0] &&
    variable[0]["where_query"] &&
    variable[0]["data_type"] &&
    variable[0]["where_query"].length
  ) {
    const whereQuery = variable[0]["where_query"];
   // console.log(variable);
    //console.log(cohortVar.filter)
    if (whereQuery != null && whereQuery !== "") {
      if (cohortVar.filter) {
       // console.log(variable[0]["data_type"])
        // where_query with filter is a template, so replace based on data_type
        switch (variable[0]["data_type"]) {
          case "continuous":
          case "integer":
            query = whereQuery
              .replace(":low", cohortVar.filter[0])
              .replace(":high", cohortVar.filter[1]);
            break;
          case "discrete":
            query = whereQuery.replace(':value', cohortVar.filter.join("','"));
            break;
          default:
            console.log("Unknown data_type: ", variable[0]["data_type"]);
        }
      } else {
        // where_query with no filter use data query to get all the values
        query = variable["data_query"];
      }
    }
  }
 // console.log(query)
  if (!query) {
    console.log("Query is null, for cohortVar:", cohortVar);
    return [];
  }
  //console.log(query);
  let personIdResults = await sqlQuery(query);
  let personIds = personIdResults.map((pid:any) => parseInt(pid.id));
  //console.log(personIds);
  return personIds;
};

/*
  Test Code:
   console.log(
      await getPersonIDs([
        { id: 1, name: "Age", filter: ["65", "87"] },
        { id: 34, name: "t(4;14)", filter: ["Present"] },
      ])
    );
*/
export const getPersonIDs = async (cohort:any): Promise<PersonIds> => {
  // cohort is of shape [{"id":1,"name":"Age","filter":["65","87"]},{"id":34,"name":"t(4;14)","filter":["Present"]}]
  if (!cohort || !Array.isArray(cohort)) {
    console.log("cohort needs to be an array");
    return [] as PersonIds;
  }

  //console.log(cohort);
  if (cohort.length === 0) {
    console.log("empty cohort, returning all persons");
    //get all the person_ids
    let sql = "SELECT omop.person.person_id as id FROM omop.person";
    const result = await sqlQuery(sql);

    if (!result) {
      return [];
    }
    let ret = result.map((d:any) => parseInt(d.id));
    return ret;
  }

  // console.log(cohort.length);
  let personIdPromises = cohort.map(
    async (filter) => await fetchFilteredPersonIds(filter)
  );
  let personIdArrays = await Promise.all(personIdPromises);
  const personIds = _.intersection(...personIdArrays);
  return personIds;
};

const getVariableEntry = async (var_key:any) => {
  const query = `select * from client.variables where var_key = '${var_key}'`;
  const result = await sqlQuery(query);
  return result;
};

//personIds is an array of unique ids from the person table
export async function getSurvivalMonths(personIds:any): Promise<any> {
  if (Number.isNaN(personIds)) {
    console.log("personIds is nan");
    return null;
  }
  // console.log(personIds);
  if (!personIds || !Array.isArray(personIds)) {
    console.log("personIds need to be an array");
    return null;
  }

  if (typeof personIds[0] != "number") {
    console.log(typeof personIds[0]);
    console.log("personIds need to be an array of numbers");
    return null;
  }

  const query = `select DATE_PART('year', age(death_date, diagnosis_date)) * 12 + DATE_PART('month', age(death_date, diagnosis_date)) as survival_rate
from (
  select min(condition_start_date) as diagnosis_date,
         max(condition_start_date) as death_date
  from omop.condition_occurrence
--   where condition_source_concept_id=437233
    where condition_status_concept_id in (32908, 32902)
    and person_id in ( ${personIds} )
  group by person_id
) as mc`

  const result1 = await sqlQuery(query) as any;
  return result1.map((d:any) => d.survival_rate);
}

//personIds is an array of unique ids from the person table
//progression free survival Months
export async function getProgressionFreeSurvivallMonths(personIds:any) {
  if (Number.isNaN(personIds)) {
    console.log("personIds is nan");
    return null;
  }
  // console.log(personIds);
  if (!personIds || !Array.isArray(personIds)) {
    console.log("personIds need to be an array");
    return null;
  }

  if (typeof personIds[0] != "number") {
    console.log(typeof personIds[0]);
    console.log("personIds need to be an array of numbers");
    return null;
  }

  const query = `select DATE_PART('year', age(death_date, diagnosis_date)) * 12 + DATE_PART('month', age(death_date, diagnosis_date)) as survival_rate
from (
  select min(condition_start_date) as diagnosis_date,
         max(condition_start_date) as death_date
  from omop.condition_occurrence
--   where condition_source_concept_id=437233
    where condition_status_concept_id in (32908, 32902)
    and person_id in ( ${personIds} )
  group by person_id
) as mc`

  const result = await sqlQuery(query);

  return result.map((d:any) => d.survival_rate);
}

/*  Returns an object 
  x_units: 'months'
  y_units: 'percent'
  y2_units: 'people'
  length: 2 //lenght of data
  data:   [ {x: 2, y: 100, y2: 300}, {x: 3, y: 99, y2: 397}] //y2 is number of people

  TEST example 
      let x = [];
    for (let i = 900; i < 1100; i++) {
      x[i - 900] = i;
    }
    console.log(await getSurvivalChartData(x));
*/
export async function getSurvivalChartData(personIds:any, name:string) {
  if (Number.isNaN(personIds)) {
    console.log("personIds is nan");
    return null;
  }

  let result = {
    x_units: "months" as string ,
    y_units: "percent" as string,
    y2_units: "persons" as string,
    personIds: personIds as PersonIds,
    length: 0 as number,
    data: [] as  { x: number, y: number, y2:any, name:string, n_persons:number}[]
  };
  let months = await getSurvivalMonths(personIds);
  //console.log(months);
  //console.log(personIds);
  if (!months) {
    console.log("months is null");
    return null;
  }
  const min = Math.min(...months);
  const max = Math.max(...months);
  let data = [];
  data[0] = { x: min - 1, y: 100, y2: months.length, name, n_persons:personIds.length };

  let n = months.length;
  let i = 0;
  for (let x = min; x < max; x++) {
    let y = months.filter((e:any) => e > x);
    if (n !== y.length) {
      n = y.length;
      let percent = (100.0 * n) / months.length;
      i++;
      data[i] = { x: x, y: percent, y2: y.length, name, n_persons:personIds.length };
    }
  }
  result = { ...result, length: data.length, data };

  return result;
}

/*  Returns an object 
  x_units: 'months'
  y_units: 'percent'
  length: 2 //lenght of data
  data:   [ {x: 2, y: 100}, {x: 3, y: 99 }]

  TEST example 
      let x = [];
    for (let i = 900; i < 1100; i++) {
      x[i - 900] = i;
    }
    console.log(await getSurvivalChartData(x));
*/
export async function getProgressionFreeSurvivalMonthsChartData(personIds:any, name:string) {
  if (Number.isNaN(personIds)) {
    console.log("personIds is nan");
    return null;
  }
  let result = {
    x_units: "months",
    y_units: "percent",
    y2_units: "persons",
    personIds: personIds,
    length: 0,
    data: [] as  { x: number, y: number, y2:any, name:string, n_persons:number}[]
  };
  let months = await getProgressionFreeSurvivallMonths(personIds);

  if (!months) {
    console.log("months is null");
    return null;
  }
  const min = Math.min(...months);
  const max = Math.max(...months);
  let data = [];
  data[0] = { x: min - 1, y: 100, y2: months.length, name, n_persons:personIds.length  };

  let n = months.length;
  let i = 0;
  for (let x = min; x < max; x++) {
    let y = months.filter((e:any) => e > x);
    if (n !== y.length) {
      n = y.length;
      let percent = (100.0 * n) / months.length;
      i++;
      data[i] = { x: x, y: percent, y2: y.length, name, n_persons:personIds.length  };
    }
  }
  result = { ...result, length: data.length, data };

  return result;
}
