import {createStore, applyMiddleware, combineReducers} from "redux"
import thunk from "redux-thunk"
import {createLogger} from "redux-logger"
import {signInReducer, signUpReducer, signUpVerifyReducer} from "./Account/reducers"
import {cohortsReducer} from "./Cohorts/reducers"
import {variablesReducer} from "./Variables/reducers"
import { chartsReducer } from "./Charts/reducers"

import createSagaMiddleware from "redux-saga";
import rootChartSaga from "./Charts/sagas";

const rootReducer = combineReducers({
    signIn: signInReducer,
    signUp: signUpReducer,
    signUpVerify: signUpVerifyReducer,
    cohorts: cohortsReducer,
    variables: variablesReducer,
    charts: chartsReducer
})

export type RootState = ReturnType<typeof rootReducer>
const sagaMiddleware = createSagaMiddleware();
const defaultMiddleware = [thunk]
const logger = createLogger() as any
const getMiddleware = () => {
    if(process.env.REACT_APP_DEBUG === 'true') {
        return [sagaMiddleware,...defaultMiddleware,logger]
    }
    return [sagaMiddleware,...defaultMiddleware]
}
const store=createStore(rootReducer, applyMiddleware(...getMiddleware()))
sagaMiddleware.run(rootChartSaga);

export default store