import {Account, Credentials} from "./models"
import {ThunkAction} from "redux-thunk"
import {RootState} from "../store"
import API from "../../api"
import {
    signInRequest,
    signInFail,
    signInSuccess,
    signUpRequest,
    signUpFail,
    signUpSuccess,
    signUpVerifyRequest,
    signUpVerifySuccess,
    signUpVerifyFail,
    signUpResendRequest,
    signUpResendFail,
    signUpResendSuccess,
    signInForgot,
    signInForgotFail,
    signInChangePassword,
    signInChangePasswordFail
} from "./actions"

type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>

export const signIn = (credentials: Credentials): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        dispatch(signInRequest(credentials))
        const data = await API.auth.login(credentials.username, credentials.password)
        if(data && data.code) {
            dispatch(signInFail([{value: data.code, param: data.name, location: '', msg: data.message}]))
            return Promise.reject(data.message)
        } else if(data && data.idToken) {
            const session = {
                firstName: data.idToken.payload.given_name,
                lastName: data.idToken.payload.family_name,
                id: data.idToken.payload.sub
            }
            localStorage.setItem("token", data.idToken.jwtToken)
            localStorage.setItem("session", JSON.stringify(session))
            API.client.defaults.headers.common['Authorization'] = data.idToken.jwtToken
            dispatch(signInSuccess())
        }
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const signUp = (account: Account): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        dispatch(signUpRequest(account))
        const validPhone = account.phone && account.phone.length ? '+1' + account.phone.replace(/\D+/g, '') : null
        const data = await API.auth.register(account.firstName, account.lastName, account.username, account.password, validPhone)
        if(data && data.code) {
            dispatch(signUpFail([{value: data.code, param: data.name, location: '', msg: data.message}]))
            return Promise.reject(data.message)
        } else {
            dispatch(signUpSuccess())
        }
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const signUpVerify = (username: string, code: number): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        dispatch(signUpVerifyRequest(username, code))
        const data = await API.auth.verify(username, code)
        if(data && data.message) {
            dispatch(signUpVerifyFail([{value: data.__type, param: data.name, location: '', msg: data.message}]))
            return Promise.reject(data.message)
        } else {
            dispatch(signUpVerifySuccess())
        }
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const signUpResend = (username: string): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        dispatch(signUpResendRequest(username))
        const data = await API.auth.resend(username)
        if(data && data.message) {
            dispatch(signUpResendFail([{value: data.__type, param: data.name, location: '', msg: data.message}]))
            return Promise.reject(data.message)
        } else {
            dispatch(signUpResendSuccess())
        }
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const signInForgotPassword = (username: string): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        dispatch(signInForgot(username))
        const data = await API.auth.forgot(username)
        if(data && data.code) {
            dispatch(signInForgotFail([{value: data.__type, param: data.name, location: '', msg: data.message}]))
            return Promise.reject(data.message)
        }
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}

export const signInChange = (username: string, code: string, password: string): ThunkResult<Promise<boolean>> => async dispatch => {
    try {
        dispatch(signInChangePassword(username))
        const data = await API.auth.changePass(username, code, password)
        console.log(data)
        if(data && data.code) {
            dispatch(signInChangePasswordFail([{value: data.__type, param: data.name, location: '', msg: data.message}]))
            return Promise.reject(data.message)
        }
    } catch (e) {
        return Promise.reject(e)
    }
    return Promise.resolve(true)
}