import {ChartType} from '../../api/chartData'
import { ChartsAction } from './models'
import {Cohort} from "../../interface"

export const types = {
    CHANGE_CHART: 'CHANGE_CHART',
    UPDATE_CHART: 'UPDATE_CHART',
    CLEAR_CHART: 'CLEAR_CHART',
    POPUP_CHART_REQUEST: 'POPUP_CHART_REQUEST',
    POPUP_CHART_SUCCESS: 'POPUP_CHART_SUCCESS',
    LOAD_CHART_ASYNC: "LOAD_CHART_ASYNC",
    POPUP_CHART_SAGA: 'POPUP_CHART_SAGA'
}

export const popupChartSaga = (line_of_therapy: number, ids: number[]): ChartsAction => ({
    type: types.POPUP_CHART_SAGA,
    input: {line_of_therapy, ids}
})


export const loadChart = (chart: ChartType, cohorts: Cohort[]): ChartsAction => ({
    type: types.LOAD_CHART_ASYNC,
    chart,
    cohorts
})

export const changeChart = (chart: ChartType): ChartsAction => ({
    type: types.CHANGE_CHART,
    chart
})

export const updateChart = (chart: ChartType, data: any): ChartsAction => ({
    type: types.UPDATE_CHART,
    chart,
    data
})

export const clearChartData = (): ChartsAction => ({
    type: types.CLEAR_CHART,
})

export const popupChartRequest = (): ChartsAction => ({
    type: types.POPUP_CHART_REQUEST
})

export const popupChartSuccess = (data: any): ChartsAction => ({
    type: types.POPUP_CHART_SUCCESS,
    data
})