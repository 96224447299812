import {types} from './actions'
import {SignInAction, SignInState, SignUpAction, SignUpState, SignUpVerifyState, SignUpVerifyAction} from "./models";

const initSignInState: SignInState = {
    loading: false,
    credentials: null,
    errors: null
}

export const signInReducer = (state = initSignInState, action: SignInAction): SignInState => {
    switch (action.type) {
        case types.SIGN_IN_REQUEST:
            return {
                loading: true,
                credentials: action.credentials,
                errors: null
            }
        case types.SIGN_IN_SUCCESS:
            return {
                loading: false,
                credentials: null,
                errors: null
            }
        case types.SIGN_IN_FAIL:
        case types.SIGN_IN_FORGOT_FAIL:
        case types.SIGN_IN_CHANGE_PASSWORD_FAIL:
            return {
                loading: false,
                credentials: null,
                errors: action.errors
            }
        default:
            return state
    }
}

const initSignUpState: SignUpState = {
    loading: false,
    account: null,
    errors: null
}

export const signUpReducer = (state = initSignUpState, action: SignUpAction): SignUpState => {
    switch (action.type) {
        case types.SIGN_UP_REQUEST:
            return {
                loading: true,
                account: action.account,
                errors: null
            }
        case types.SIGN_UP_SUCCESS:
            return {
                loading: false,
                account: null,
                errors: null
            }
        case types.SIGN_UP_FAIL:
            return {
                loading: false,
                account: null,
                errors: action.errors
            }
        default:
            return state
    }
}

const initSignUpVerifyState: SignUpVerifyState = {
    loading: false,
    username: null,
    code: null,
    errors: null
}

export const signUpVerifyReducer = (state = initSignUpVerifyState, action: SignUpVerifyAction): SignUpVerifyState => {
    switch (action.type) {
        case types.SIGN_UP_VERIFY_REQUEST:
            return {
                loading: true,
                username: action.username,
                code: action.code,
                errors: null
            }
        case types.SIGN_UP_VERIFY_SUCCESS:
            return {
                loading: false,
                username: null,
                code: null,
                errors: null
            }
        case types.SIGN_UP_RESEND_FAIL:
        case types.SIGN_UP_VERIFY_FAIL:
            return {
                loading: false,
                username: null,
                code: null,
                errors: action.errors
            }
        default:
            return state
    }
}