import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse,
  makeStyles,
  Divider,
  Box,
  CircularProgress,
} from "@material-ui/core";
import {
  LocalPharmacy,
  GroupWork,
  People,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDna } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { addVariable } from "../redux/Cohorts/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  subheaders: {
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
  nestedText: {
    fontSize: 14,
  },
  label: {
    width: "100%",
    marginRight: 0,
  },
  loadingBox: {
    height: "100%",
  },
}));

const VariablesList = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState("demographics");

  const { loading, variables, addVariable } = props;

  if (loading)
    return (
      <Box
        className={classes.loadingBox}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <List>
      {variables.map((title) => (
        <div key={`title-${title.id}`}>
          <ListSubheader component="div" className={classes.subheaders}>
            {title.name}
          </ListSubheader>
          <Divider />
          {title.children.map((group) => (
            <div key={`group-${group.id}`}>
              <ListItem
                button
                onClick={() => {
                  group.id === open ? setOpen("") : setOpen(group.id);
                }}
              >
                <ListItemIcon>
                  {group.type === "patient" ? <People /> : null}
                  {group.type === "lab" ? <GroupWork /> : null}
                  {group.type === "genomic" ? (
                    <FontAwesomeIcon icon={faDna} />
                  ) : null}
                  {group.type === "manufacturing" ? <LocalPharmacy /> : null}
                </ListItemIcon>
                <ListItemText primary={group.name} />
                {group.id === open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={group.id === open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {group.children.map((variable) => (
                    <ListItem
                      key={variable.var_key}
                      button
                      className={classes.nested}
                      onClick={() =>
                        addVariable(variable.var_key, variable.name)
                      }
                    >
                      <ListItemText
                        primary={variable.name}
                        classes={{ primary: classes.nestedText }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </div>
      ))}
    </List>
  );
};

const mapStateToProps = (state) => ({
  ...state.variables,
});

const mapDispatch = {
  addVariable,
};

export default connect(mapStateToProps, mapDispatch)(VariablesList);
